import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

/**
 * @param {HTMLElement} - Name of the section that contains numbers
 */
export default class Numbers {
  constructor(container) {
    if (!container) return

    // DOM
    this.DOM = { container }
    this.DOM.numbers = this.DOM.container.querySelectorAll('.number')

    if (!this.DOM.numbers && !this.DOM.numbers.length) return

    this.tweens = []
    this.DOM.numbers.forEach(number => this.initTween(number))
  }

  initTween(el) {
    const span = el.querySelector('span[data-number]')

    const tween = gsap.to(span, { 
      textContent: span.dataset.number, 
      duration: 1.5, 
      ease: 'power4.out', 
      snap: { textContent: 1 },
      scrollTrigger: {
        trigger: el,
        start: 'top 80%'
      }
    })

    this.tweens.push(tween)
  }

  destroy() {
    this.tweens && this.tweens.length && this.tweens.forEach(tween => tween.kill())
  }
}