import { viewStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'
gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin)

export default class EventInscription {
  constructor(container) {
    if (!container) return

    // DOM 
    this.DOM = { container }
    this.DOM.path = this.DOM.container.querySelector('.Icon.--event-inscription #eventPathMask')
    this.DOM.visual = this.DOM.container.querySelector('.visual__container')

    // Settings
    // MatchMedia variable
    this.mm = gsap.matchMedia()
    // Breakpoint
    this.breakpoint = 768
    // Responsive object
    this.responsiveObj = {
      isDesktop: `(min-width: ${this.breakpoint}px)`,
      isMobile: `(max-width: ${this.breakpoint - 1}px)`
    }

    // Animations
    this.mm.add(this.responsiveObj, (context) => {
      const { isDesktop } = context.conditions
      
      if (isDesktop) {
        // Visual parallax
        if (this.DOM.visual) {
          context.visualTween = gsap.fromTo(this.DOM.visual, {
            yPercent: 50,
            rotate: -2
          }, {
            yPercent: -50,
            rotate: 4.5,
            ease: 'none',
            scrollTrigger: {
              trigger: this.DOM.container,
              scrub: true,
            }
          })
        }

        // Icon 
        context.svgPathTween = gsap.fromTo(this.DOM.path, {
          drawSVG: '0%'
        }, {
          drawSVG: '100%',
          duration: 2,
          delay: 0.5,
          ease: 'power3.inOut',
          scrollTrigger: {
            trigger: this.DOM.container,
          }
        })
      }
    })
  }

  destroy() {
    this.mm.revert()
  }
}