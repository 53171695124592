import { isDEVMODE, domStorage, viewStorage } from '../_globals'
import viewScroll from './view-scroll'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
import Lenis from '@studio-freight/lenis'

/**
 * @param {HTMLElement} - Name of the section that contains action card links
 */
export default class ActionFieldPopups {
  constructor(container) {
    if (!container) return

    // DOM
    this.DOM = { container }
    this.DOM.links = this.DOM.container.querySelectorAll('.ActionCard')

    if (!this.DOM.links && !this.DOM.links.length) return

    // Settings
    this.currentPage = window.location.href

    // Events
    this.createPopup = this.createPopup.bind(this)
    this.close = this.close.bind(this)

    this.DOM.links.forEach(link => link.addEventListener('click', this.createPopup))
  }

  createPopup(e) {
    e.preventDefault()

     const { body } = domStorage
     const { href } = e.currentTarget

     // Stop smooth scroll on the page
     viewStorage.viewScroll.scroll.stop()

     body.classList.add('--loading')
     body.classList.add('--show-action-field')

     fetch(href)
      .then((req) => req.text())
      .then(data => {
        body.classList.remove('--loading')

        // Getting the content section
        const parser = new DOMParser()
        const doc = parser.parseFromString(data, 'text/html')
        this.DOM.actionFieldContent = doc.querySelector('.ActionFieldContent')
        this.DOM.actionFieldPopup = this.DOM.actionFieldContent.querySelector('.popup')
        this.DOM.actionFieldBackLink = this.DOM.actionFieldContent.querySelector('.back > a')
        this.DOM.actionFiledBackClose = this.DOM.actionFieldContent.querySelector('.back > .Close')
        
        // Append to the current page
        body.appendChild(this.DOM.actionFieldContent)
        
        // Only display the back button (and not the link)
        this.DOM.actionFieldBackLink && this.DOM.actionFieldBackLink.remove()
        
        // Custom smooth scroll for the popup
        this.actionFieldScroll = new viewScroll({
          wrapper: this.DOM.actionFieldContent,
          content: this.DOM.actionFieldContent.children[0]
        })

        // Update window history with the current href
        window.history.replaceState("", "", href)

        // Events
        this.DOM.actionFiledBackClose.addEventListener('click', this.close)

        // Open animation
        this.open()
      })
  }

  destroyPopup() {
    this.actionFieldScroll.scroll.destroy()
    this.DOM.actionFieldContent.remove()
    this.tlIn && this.tlIn.kill()
    this.tlOut && this.tlOut.kill()
    this.DOM.actionFiledBackClose.removeEventListener('click', this.close)

    viewStorage.viewScroll.scroll.start()

    domStorage.body.classList.remove('--show-action-field')

    window.history.replaceState("", "", this.currentPage)
  }

  open() {
    this.tlIn = gsap.timeline({
      defaults: {},
      onStart: () => {}
    })
    .from(this.DOM.actionFieldContent, { opacity: 0, ease: 'power3.out', duration: 1 }, 0)
    .from(this.DOM.actionFieldPopup, { yPercent: 50, opacity: 0, scale: 0.8, ease: 'elastic.out(1,0.95)', duration: 0.75 }, 0.85)
  }

  close(e) {
    e && e.preventDefault()

    this.tlOut = gsap.timeline({
      defaults: {},
      onComplete: () => {
        this.destroyPopup()
      }
    })
    .to(this.DOM.actionFieldPopup, { yPercent: 50, opacity: 0, scale: 0.8, ease: 'elastic.in(1,0.95)', duration: 0.75 }, 0)
    .to(this.DOM.actionFieldContent, { opacity: 0, ease: 'power3.out', duration: 1 })
  }

  destroy() {
    this.DOM.links && this.DOM.links.length && this.DOM.links.forEach(link => link.removeEventListener('click', this.createPopup))
  }
}
