import { Renderer } from '@unseenco/taxi'
import { isDEVMODE, viewStorage } from '../_globals'
import WknSwiper from '../modules/wkn-swiper'
import MobileSwiper from '../modules/mobile-swiper'

export default class SingleActionField extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    // DOM
    this.DOM = {
      closeButton: viewStorage.current.querySelector('.Close')
    }

    // We remove the Close button when the page is not a popup
    this.DOM.closeButton && this.DOM.closeButton.remove()
  }
}
