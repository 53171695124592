export default class AnnuaireFilters {

  constructor (form, searchInput, cardList, total, totalPlural, noResult) {
    this.form = form
    this.searchInput = searchInput
    this.cardList = cardList
    this.total = total
    this.totalPlural = totalPlural
    this.noResult = noResult

    this.init()
  }

  init () {
    this.form.addEventListener('change', () => {
      this.filter()
    })
    this.searchInput && this.searchInput.addEventListener('input', () => {
      this.filter(false)
    })
  }

  filter (immediate = true) {
    if (immediate) {
      this.filterCards()
    } else {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => this.filterCards(), 500)
    }
  }

  filterCards () {
    const filters = new FormData(this.form)
    const search = this.searchInput ? this.searchInput.value : ''
    const url = this.form.getAttribute('action')
    const params = new URLSearchParams(filters)
    params.append('search', search)
    fetch(`${url}&${params.toString()}`)
      .then(response => response.json())
      .then(data => {
        this.total.textContent = data.total
        this.totalPlural.style.display = data.total > 1 ? 'inline' : 'none'
        this.noResult.style.display = data.total === 0 ? 'block' : 'none'
        if (data.total > 0) {
          this.cardList.innerHTML = data.html
        } else {
          this.cardList.innerHTML = ''
        }
        const title = document.querySelector('h1')
        console.log(title, data.title)
        title.innerHTML = data.title
      })
  }

  destroy () {
    this.form.removeEventListener('change', () => {
      this.filter()
    })
    this.searchInput && this.searchInput.removeEventListener('input', () => {
      this.filter(false)
    })
  }
}
