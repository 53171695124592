/* global gtag */

/* --- Load Plugins / Functions --- */
import { isDEVMODE, globalStorage, viewStorage, domStorage, moduleStorage } from './_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

import { updateHeaderTheme } from './utils'
import { GlobalIntro } from './animations/global-intro'

import viewScroll from './modules/view-scroll.js'
import viewPrllx from './modules/view-prllx.js'
import viewInView from './modules/view-inview.js'
import viewModals from './modules/view-modal'

import Contact from './modules/contact'
import ContactPopups from './modules/contact-popups'
import NewsletterPopups from './modules/newsletter-popups'
import TrophyPopups from './modules/trophy-popups'

/* --- DOMContentLoaded Function --- */
export const onReady = () => {
  const { body } = domStorage

  viewStorage.viewScroll = new viewScroll()
  viewStorage.viewPrllx = new viewPrllx(body)
  viewStorage.viewInView = new viewInView(body)
  viewStorage.viewModals = new viewModals(body)

  // Intro
  GlobalIntro()

  // Update header theme
  updateHeaderTheme(viewStorage.current)

  // General modules
  moduleStorage.contact = new Contact()
  moduleStorage.contactPopups = new ContactPopups()
  moduleStorage.newsletterPopups = new NewsletterPopups()
  moduleStorage.trophyPopups = new TrophyPopups()
}

export const onEnter = (to, trigger) => {

  // LoadingClasses
  const { body } = domStorage
  body.classList.remove('--loading')

  // ScrollTop
  window.scrollTo(globalStorage.pageScrollTop, globalStorage.pageScrollTop)

  // Update Current View
  viewStorage.current = to.renderer.content

  // Add Page Effects
  body.style.overflow = ''

  viewStorage.viewScroll.scroll.start()
  viewStorage.viewPrllx = new viewPrllx()
  viewStorage.viewInView = new viewInView()
  viewStorage.viewModals = new viewModals()

  // Update header theme
  updateHeaderTheme(viewStorage.current)

}

export const onEnterCompleted = (to, from, trigger) => {

  // if (isDEVMODE) console.log('onEnterCompleted')

  const { targetLocation } = globalStorage.taxi

  // Anchor
  if (targetLocation.hasHash) {
    const anchorEl = document.querySelector(window.location.hash)
    if (anchorEl) gsap.to(window, {duration: 1.8, scrollTo: { y: anchorEl, autoKill: false }, overwrite: 'all', ease: 'expo.inOut' })
  }

  // Analytics
  var _hsq = window._hsq = window._hsq || [];
  _hsq.push(['setPath', targetLocation.raw]);
  _hsq.push(['trackPageView']);

  // General modules
  moduleStorage.contact = new Contact()
  moduleStorage.contactPopups = new ContactPopups()
  moduleStorage.newsletterPopups = new NewsletterPopups()
  // moduleStorage.trophyPopups = new TrophyPopups()
}

export const onLeave = (from, trigger) => {

  // Remove Previous Page Effects
  const { hasInView, hasSticky, hasPrllx, hasModals, hasCursor } = viewStorage

  hasInView && viewStorage.viewInView.destroy()
  hasSticky && viewStorage.viewSticky.destroy()
  hasPrllx && viewStorage.viewPrllx.destroy()
  hasModals && viewStorage.viewModals.destroy()
  hasCursor && globalStorage.Cursor.removeHovers()

  // LoadingClasses
  const { body } = domStorage
  body.classList.add('--loading')
  body.classList.add('--animating')

  const { menu } = domStorage
  const { menuOpen } = globalStorage

  // Close Menu
  menuOpen && globalStorage.closeMobileMenu()

  // Remove active links
  const { targetLocation } = globalStorage.taxi
  const navLinks = menu.querySelectorAll('.Nav__link')
  navLinks.forEach((link) => {
    if (link.href === targetLocation.raw) link.classList.add('--active')
    else link.classList.remove('--active')
  })

  // General modules
  if (moduleStorage.contact) moduleStorage.contact.destroy()
  if (moduleStorage.contactPopups) moduleStorage.contactPopups.destroy()
  if (moduleStorage.newsletterPopups) moduleStorage.newsletterPopups.destroy()
  // if (moduleStorage.trophyPopups) moduleStorage.trophyPopups.destroy()

}
