import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
// import NewsFilters from '../modules/news-filters'
import FloatingItems from '../modules/floating-items'
import ResourcesFilters from '../modules/resources-filters'
import NewsFilters from '../modules/news-filters'

export default class ArchiveResources extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    // DOM
    this.DOM = {
      form: viewStorage.current.querySelector('#resources-filters'),
      hitDirectory: viewStorage.current.querySelector('.HitDirectory'),
    }

    // Modules
    if (this.DOM.form) this.resourcesFiltersModule = new NewsFilters(this.DOM.form)
    if (this.DOM.hitDirectory) this.contactFloatingItemsModule = new FloatingItems(this.DOM.hitDirectory)
  }

  onLeaveCompleted() {
    this.resourcesFiltersModule && this.resourcesFiltersModule.destroy()
    this.contactFloatingItemsModule && this.contactFloatingItemsModule.destroy()
  }
}
