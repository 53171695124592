import { Renderer } from '@unseenco/taxi';
import { viewStorage } from '../_globals'
import MobileSwiper from '../modules/mobile-swiper'
import AnnuaireFilters from '../modules/AnnuaireFilters'

export default class ArchiveAnnuaire extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    // DOM
    this.DOM = {
      latestNewsSwiper: viewStorage.current.querySelector('.LatestNews .swiper.--mobile'),
      total: viewStorage.current.querySelector('.total'),
      totalPlural: viewStorage.current.querySelector('.total_plural'),
      noResult: viewStorage.current.querySelector('.no-results'),
      cardList: viewStorage.current.querySelector('.cardsContainer'),
      form: viewStorage.current.querySelector('.annuaireFilters'),
      searchInput: viewStorage.current.querySelector('.searchInput')
    }

    // Modules
    if (this.DOM.latestNewsSwiper) this.latestNewsSwiperModule = new MobileSwiper(this.DOM.latestNewsSwiper)
    if (this.DOM.form) this.annuaireFilters = new AnnuaireFilters(this.DOM.form, this.DOM.searchInput, this.DOM.cardList, this.DOM.total, this.DOM.totalPlural, this.DOM.noResult)
  }

  onLeaveCompleted() {
    this.latestNewsSwiperModule && this.latestNewsSwiperModule.destroy()
    this.annuaireFilters && this.annuaireFilters.destroy()
  }
}
