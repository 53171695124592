import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import NewsFilters from '../modules/news-filters'

export default class Archive extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    // DOM
    this.DOM = {
      form: viewStorage.current.querySelector('#news-filters')
    }
    console.log('Archive page initialized', this.DOM)

    // Modules
    if (this.DOM.form) this.newsFiltersModule = new NewsFilters(this.DOM.form)
  }

  onLeaveCompleted() {
    this.newsFiltersModule && this.newsFiltersModule.destroy()
  }
}
