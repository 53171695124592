import { isDEVMODE, viewStorage } from '../_globals'

/**
 * @param {HTMLElement} - Form
 */
export default class NewsFilters {

  constructor(form) {
    if (!form) return
    console.log('NewsFilters module initialized')
    // DOM
    this.DOM = { form }
    this.DOM.categoriesInputs = this.DOM.form.querySelectorAll('.form-categories input')
    this.DOM.tagsSelect = this.DOM.form.querySelector('.form-tags select')
    this.page = 1
    this.DOM.loadMoreButton = document.querySelector('.load-more-button')
    this.DOM.newsContainer = document.querySelector('#results')

    // Events
    this.DOM.categoriesInputs && this.DOM.categoriesInputs.forEach(input => input.addEventListener('change', this.onCategoryChange.bind(this)))
    this.DOM.tagsSelect && this.DOM.tagsSelect.addEventListener('change', this.onTagChange.bind(this))
    this.DOM.loadMoreButton && this.DOM.loadMoreButton.addEventListener('click', this.onLoadMore.bind(this))
    this.DOM.form.addEventListener('submit', this.onSubmit.bind(this))
  }

  onSubmit(e)  {
    console.log("onSubmit")
    if (e) e.preventDefault()
    const formData = new FormData(this.DOM.form)
    console.log('formData', formData)
    formData.append('page', this.page)
    formData.delete('categories[]')
    //remove categories
    // formData.set('categories[]', null)
    // Replace categories with checked ones (not 'on' but the value)
    const categories = []
    this.DOM.categoriesInputs.forEach(input => {
      if (input.checked) {
        formData.append('categories[]', input.value)
      }
    })
    console.log("categories", categories)
    const url = this.DOM.form.dataset.ajaxUrl
    const headers = new Headers()
    headers.append('X-Requested-With', 'XMLHttpRequest')
    fetch(url, {
      method: 'POST',
      headers,
      body: formData
    })
    .then(response => response.json())
      .then(data => {
        // The data will be : posts: [], loadMore: true|false, html: '' . If the page is > 1, we append the new posts to the container. If the page is 1, we replace the container's content with the new posts.
        if (this.page === 1) {
          this.DOM.newsContainer.innerHTML = data.html
        } else {
          this.DOM.newsContainer.innerHTML += data.html
        }
        if (data.hasMore) {
          this.DOM.loadMoreButton.classList.remove('--hidden')
        } else {
          this.DOM.loadMoreButton.classList.add('--hidden')
        }
      })
  }

  onLoadMore(e) {
    isDEVMODE && console.log('onLoadMore')
    e.preventDefault()
    this.page++
    this.onSubmit()
  }

  onCategoryChange(e) {
    const category = e.target.value
    this.page = 1
    if (category === 'all') {
      this.uncheckCategories()
    } else {
      this.DOM.categoriesInputs[0].checked = false
    }
    this.onSubmit()
  }

  onTagChange(e) {
    this.page = 1
    const tag = e.target.value
    if (tag === 'all') {
      this.DOM.tagsSelect.value = ''
    }
    this.onSubmit()
  }

  uncheckCategories () {
    this.DOM.categoriesInputs.forEach(input => {
      if (input.value !== 'all') input.checked = false
    })
  }

  destroy() {
    this.DOM.categoriesInputs && this.DOM.categoriesInputs.forEach(input => input.removeEventListener('change', this.onCategoryChange.bind(this)))
    this.DOM.tagsSelect && this.DOM.tagsSelect.removeEventListener('change', this.onTagChange.bind(this))
    this.DOM.loadMoreButton && this.DOM.loadMoreButton.removeEventListener('click', this.onLoadMore.bind(this))
    this.DOM.form.removeEventListener('submit', this.onSubmit.bind(this))
  }
}
