import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class HomeSquaresParallax {
  constructor(container) {
    if (!container) return

    this.DOM = { container }
    this.DOM.gallery = this.DOM.container.querySelector('.gallery')
    this.DOM.squares = this.DOM.container.querySelectorAll('.square-visual')

    if (!this.DOM.squares && !this.DOM.squares.length) return

    // // Manage the parallax of each item
    this.parallaxObject = [
      { yPercent: 80, rotate: -4 },
      { yPercent: 160, rotate: 2 },
      { yPercent: -20, rotate: 5 },
      { yPercent: -130, rotate: -2 },
      { yPercent: -40, rotate: -7 },
      { yPercent: -30, rotate: 0 },
      { yPercent: -100, rotate: 3 },
      { yPercent: 50, rotate: -4 },
    ]

    this.scrollTrigger = gsap.timeline({
      scrollTrigger: {
        trigger: this.DOM.gallery,
        start: 'top top',
        end: 'bottom top',
        scrub: true
      }
    })
    .to(this.DOM.squares, {
      yPercent: (i, el, els) => this.parallaxObject[i].yPercent,
      rotate: (i, el, els) => this.parallaxObject[i].rotate,
    })
  }

  destroy() {
    this.scrollTrigger && this.scrollTrigger.kill()
  }
}
