
import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import WknSwiper from '../modules/wkn-swiper'

export default class PageTrophy extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    // DOM
    this.DOM = {
      awardsSwiper: viewStorage.current.querySelector('.Awards .swiper')
    }

    // Modules
    if (this.DOM.awardsSwiper) this.awardsSwiperModule = new WknSwiper(this.DOM.awardsSwiper, { slidesPerView: 'auto' })
  }

  onLeaveCompleted() {
    this.awardsSwiperModule && this.awardsSwiperModule.destroy()
  }
}
