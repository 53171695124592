import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin)

/**
 * @param {HTMLElement} - Name of the section that contains accordions
 */
export default class Accordions {
  constructor(container) {
    if (!container) return

    this.DOM = { container }
    this.DOM.accordions = this.DOM.container.querySelectorAll('.Accordion')
    this.DOM.accordionsButtons = this.DOM.container.querySelectorAll('.Accordion .head')

    if (!this.DOM.accordionsButtons && !this.DOM.accordionsButtons.length) return

    // Events
    this.toggle = this.toggle.bind(this)

    this.DOM.accordionsButtons.forEach(button => button.addEventListener('click', this.toggle))

    // Open the first accordion by default
    this.DOM.accordions[0].classList.add('--active')
    this.DOM.accordions[0].querySelector('.content').style.height = `${this.DOM.accordions[0].querySelector('.content').scrollHeight}px`

  }

  toggle(e) {
    e && e.preventDefault()

    const currentAccordion = e.currentTarget.parentNode
    const content = currentAccordion.querySelector('.content')

    currentAccordion.classList.toggle('--active')

    content.style.height ?
      content.style.height = null :
      content.style.height = `${content.scrollHeight}px`
  }

  destroy() {
    this.DOM.accordionsButtons && 
      this.DOM.accordionsButtons.length && 
      this.DOM.accordionsButtons.forEach(button => button.removeEventListener('click', this.toggle))
  }
}
