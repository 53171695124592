
import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import WknSwiper from '../modules/wkn-swiper'
import MobileSwiper from '../modules/mobile-swiper'
import Accordions from '../modules/accordions'
import ActionFieldPopups from '../modules/action-field-popups'
import LoadMore from '../modules/load-more'
import FixedNavigation from '../modules/fixed-navigation'

export default class PageAbout extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    // DOM
    this.DOM = {
      missionSwiper: viewStorage.current.querySelector('.Mission .swiper'),
      expertise: viewStorage.current.querySelector('.Expertise'),
      actionFields: viewStorage.current.querySelector('.ActionFields'),
      actionFieldsSwiper: viewStorage.current.querySelector('.ActionFields .swiper.--mobile'),
      testimonialSwiper: viewStorage.current.querySelector('.Testimonials .swiper'),
      teamSwiper: viewStorage.current.querySelector('.Team .swiper'),
      experts: viewStorage.current.querySelector('.Team.--experts'),

      // Navigation
      fixedNavigation: viewStorage.current.querySelector('.fixed-navigation'),
      fixedNavigationSections: [...viewStorage.current.querySelectorAll('.section-nav-item')]
    }

    // Modules
    if (this.DOM.missionSwiper) this.missionSwiperModule = new WknSwiper(this.DOM.missionSwiper, { slidesPerView: 'auto', spaceBetween: 0, centerInsufficientSlides: true })
    if (this.DOM.expertise) this.expertiseAccordionsModule = new Accordions(this.DOM.expertise)
    if (this.DOM.actionFields) this.actionFieldsPopupsModule = new ActionFieldPopups(this.DOM.actionFields)
    if (this.DOM.actionFieldsSwiper) this.actionFieldsSwiperModule = new MobileSwiper(this.DOM.actionFieldsSwiper)
    if (this.DOM.testimonialSwiper) this.testimonialSwiperModule = new WknSwiper(this.DOM.testimonialSwiper, { slidesPerView: 'auto', spaceBetween: 20, centerInsufficientSlides: true })
    if (this.DOM.teamSwiper) this.teamSwiperModule = new WknSwiper(this.DOM.teamSwiper, { slidesPerView: 'auto' })
    if (this.DOM.fixedNavigation) this.fixedNavigationModule = new FixedNavigation(this.DOM.fixedNavigation, this.DOM.fixedNavigationSections)

    if (this.DOM.experts) this.expertsLoadMoreModule = new LoadMore({
      container: this.DOM.experts,
      items: this.DOM.experts.querySelectorAll('.ProfessionalCard'),
      limit: 12,
      increment: 6
    })
  }

  onLeaveCompleted() {
    this.fixedNavigationModule && this.fixedNavigationModule.destroy()
    this.missionSwiperModule && this.missionSwiperModule.destroy()
    this.actionFieldsPopupsModule && this.actionFieldsPopupsModule.destroy()
    this.expertiseAccordionsModule && this.expertiseAccordionsModule.destroy()
    this.actionFieldsSwiperModule && this.actionFieldsSwiperModule.destroy()
    this.testimonialSwiperModule && this.testimonialSwiperModule.destroy()
    this.teamSwiperModule && this.teamSwiperModule.destroy()
    this.expertsLoadMoreModule && this.expertsLoadMoreModule.destroy()
  }
}
