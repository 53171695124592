import { Renderer } from '@unseenco/taxi';

export default class DefaultPageRenderer extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {}
}
