
import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import Accordions from '../modules/accordions'
import WknSwiper from '../modules/wkn-swiper'
import MobileSwiper from '../modules/mobile-swiper'
import Numbers from '../modules/numbers'
import EventInscription from '../modules/event-inscription'


export default class PageGutenberg extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    // DOM
    this.DOM = {
      missionSwipers: viewStorage.current.querySelectorAll('.Mission .swiper'),
      expertiseSections: viewStorage.current.querySelectorAll('.Expertise'),
      testimonialSwiper: viewStorage.current.querySelector('.Testimonials .swiper'),
      numbersSections: viewStorage.current.querySelectorAll('.Numbers'),
      crossPostsSwiperMobile: viewStorage.current.querySelector('.CrossPosts .swiper.--mobile'),
      eventInscriptionSections: viewStorage.current.querySelectorAll('.EventInscription')
    }

    // Modules
    // • Mission Swipers
    this.missionSwipers = []
    if (this.DOM.missionSwipers && this.DOM.missionSwipers.length) {
      this.DOM.missionSwipers.forEach(swiper => {
        this.missionSwipers.push(new WknSwiper(swiper, { slidesPerView: 'auto', spaceBetween: 0, centerInsufficientSlides: true }))
      })
    }

    // • Accordions
    this.expertisesModules = []
    if (this.DOM.expertiseSections && this.DOM.expertiseSections.length) {
      this.DOM.expertiseSections.forEach(section => {
        this.expertisesModules.push(new Accordions(section))
      })
    }

    // • Testimonials
    if (this.DOM.testimonialSwiper) {
      this.testimonialSwiperModule = new WknSwiper(this.DOM.testimonialSwiper, { slidesPerView: 'auto', spaceBetween: 20, centerInsufficientSlides: true })
    }

    // • Numbers
    this.numbersModules = []
    if (this.DOM.numbersSections && this.DOM.numbersSections.length) {
      this.DOM.numbersSections.forEach(section => {
        this.numbersModules.push(new Numbers(section))
      })
    }

    // • CrossPosts
    if (this.DOM.crossPostsSwiperMobile) {
      this.crossPostsSwiperMobileModule = new MobileSwiper(this.DOM.crossPostsSwiperMobile)
    }

    // • Event inscription
    this.eventInscriptionModules = []
    if (this.DOM.eventInscriptionSections && this.DOM.eventInscriptionSections.length){
      this.DOM.eventInscriptionSections.forEach(section => {
        this.eventInscriptionModules.push(new EventInscription(section))
      })
    }
  }

  onLeaveCompleted() {
    this.expertisesModules && this.expertisesModules.length && this.expertisesModules.forEach(module => module.destroy())
    this.missionSwipers && this.missionSwipers.length && this.missionSwipers.forEach(module => module.destroy())
    this.testimonialSwiperModule && this.testimonialSwiperModule.destroy()
    this.numbersModules && this.numbersModules.length && this.numbersModules.forEach(module => module.destroy())
    this.crossPostsSwiperMobileModule && this.crossPostsSwiperMobileModule.destroy()
    this.eventInscriptionModules && this.eventInscriptionModules.length && this.eventInscriptionModules.forEach(module => module.destroy())
  }
}
