
import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage, domStorage } from '../_globals'
import WknSwiper from '../modules/wkn-swiper'
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin)

export default class PageWit extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    // DOM
    this.DOM = {
      teamSwiper: viewStorage.current.querySelector('.Team .swiper'),
      programTabs: viewStorage.current.querySelectorAll('.Program__title ul li'),
      programContent: viewStorage.current.querySelectorAll('.Program__content'),
      swiperLogo : viewStorage.current.querySelector('.Partners__list .swiper'),
    }

    // Modules
    if (this.DOM.teamSwiper) this.teamSwiperModule = new WknSwiper(this.DOM.teamSwiper, { slidesPerView: 'auto' })

    this.init()
  }

  init() {
    const { 
      programTabs = null,
      programContent = null, 
      swiperLogo = null 
    } = this.DOM

    // Header anchors
    this.goToSection = this.goToSection.bind(this)

    if (domStorage.header) {
      this.DOM.headerLinks = domStorage.header.querySelectorAll('.Nav__link')

      this.DOM.headerLinks && 
        this.DOM.headerLinks.length && 
          this.DOM.headerLinks.forEach(item => item.addEventListener('click', this.goToSection))
    }

    // Tabs 
    programTabs.forEach(tab => {
      tab.addEventListener('click', () => {
          programTabs.forEach(tab => tab.classList.remove('--active'));
          tab.classList.add('--active');

          const className = tab.classList.contains('inspire') ? '--inspire' : '--equip';

          programContent.forEach(content => { 
              content.classList.remove('--active');
              if (content.classList.contains(className)) {
                  content.classList.remove('--active');
                  content.classList.add('--active');
              }
          });
      });
    });

    // Logo partenaires
    if (this.DOM.swiperLogo) {
      this.swiperLogo = new WknSwiper(swiperLogo, { 
        slidesPerView:'auto',
        spaceBetween: 56
      })
    }
  }

  goToSection(e) {
    e && e.preventDefault()
    
    const link = e.currentTarget
    const hash = link.getAttribute('href')
    const section = viewStorage.current.querySelector(hash)
        
    if (!section) return

    gsap.to(window, { scrollTo: section, ease: 'expo.inOut', duration: 2 })
  }

  onLeaveCompleted() {
    if (domStorage.header) {
      this.DOM.headerLinks = domStorage.header.querySelectorAll('.Nav__link')

      this.DOM.headerLinks && 
        this.DOM.headerLinks.length && 
          this.DOM.headerLinks.forEach(item => item.removeEventListener('click', this.goToSection))
    }

    this.teamSwiperModule && this.teamSwiperModule.destroy()
    this.swiperLogo && this.swiperLogo.destroy()
  }
}
