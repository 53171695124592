import { Renderer } from '@unseenco/taxi'
import ContactHubspot from '../modules/contact-hubspot'
import { viewStorage } from '../_globals'

export default class PageContact extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    this.DOM = {
      close: viewStorage.current.querySelector('.Close')
    }

    if (this.DOM.close) this.DOM.close.remove() 

    this.contactHubspotModule = new ContactHubspot(viewStorage.current)
  }

  onLeaveCompleted() {
    this.contactHubspotModule && this.contactHubspotModule.destroy()
  }
}
