import { viewStorage, domStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'
gsap.registerPlugin(ScrollTrigger, SplitText, DrawSVGPlugin)

/**
 * All animations for different parts of the DOM
 */

export const header = () => {
  if (!domStorage.header) return

  const tween = gsap.fromTo(domStorage.header, {
    yPercent: -100,
    opacity: 0,
  }, {
    yPercent: 0,
    opacity: 1,
    ease: 'power3.inOut',
    duration: 1,
    onComplete: () => {
      gsap.set(domStorage.header, { clearProps: 'yPercent,opacity' })
    }
  })

  return tween
}

export const footer = () => {
  if (!domStorage.footer) return

  const tween = gsap.fromTo(domStorage.footer, {
    opacity: 0,
  }, {
    opacity: 1,
    ease: 'power3.inOut',
    duration: 1,
    onComplete: () => {
      gsap.set(domStorage.footer, { clearProps: 'opacity' })
    }
  })

  return tween
}

export const basicIntro = () => {
  const tween = gsap.from(viewStorage.current, {
    y: 20, 
    opacity: 0,
    ease: 'power3.out',
    duration: 1,
    onComplete: () => {
      gsap.set(viewStorage.current, { clearProps: 'y,opacity' })
    }
  })

  return tween
}

export const pageHeaderHome = () => {
  const is_home = viewStorage.current.dataset.taxiView === 'frontPage'
  const pageHeader = viewStorage.current.querySelector('.PageHeader')

  if (!is_home || !pageHeader) return

  const title = pageHeader.querySelector('h1')
  const titleLines = new SplitText(title, { type: 'lines' })
  const content = pageHeader.querySelectorAll('.wswyg--content')
  const pageHeaderIconPath = pageHeader.querySelector('.Icon svg > mask > path')
  const squares = viewStorage.current.querySelectorAll('.gallery .square-visual')

  const tl = gsap.timeline({
    defaults: { ease: 'power3.out' },
    onComplete: () => {
      titleLines && titleLines.revert()

      gsap.set(content, { clearProps: 'y,opacity' })
      gsap.set(pageHeader, { clearProps: 'y,rotate,scale,opacity' })
      gsap.set(pageHeaderIconPath, { clearProps: 'drawSVG' })
    }
  })
  .fromTo(pageHeaderIconPath, { drawSVG: '0%' }, { drawSVG: '100%', ease: 'expo.inOut', duration: 2 }, 0)
  .from(titleLines.lines, { y: 20, opacity: 0, duration: 1, stagger: 0.1 }, 1.25)
  .from(content, { y: 20, opacity: 0, duration: 1 }, 1.35)
  .from(squares, { opacity: 0, scale: 0, stagger: 0.1 }, 2)

  return tl
}

export const pageHeaderIntro = () => {
  const is_home = viewStorage.current.dataset.taxiView === 'frontPage'
  const pageHeader = viewStorage.current.querySelector('.PageHeader')

  if (is_home || !pageHeader) return

  const subtitle = pageHeader.querySelector('.subtitle')
  const title = pageHeader.querySelector('h1')
  const titleLines = new SplitText(title, { type: 'lines' })
  const titleStrongs = pageHeader.querySelectorAll('h1 strong')
  const content = pageHeader.querySelectorAll('.wswyg--content')
  const searchTitle = pageHeader.querySelector('.--search h2')
  const searchBar = pageHeader.querySelector('.SearchBar')
  const pageHeaderIconPath = pageHeader.querySelector('.Icon svg > mask > path')

  // For About page, we need to add manually strong colors
  if (viewStorage.current.dataset.taxiView === 'pageAbout') {
    titleStrongs && titleStrongs.length && titleStrongs.forEach((item, index) => {
      index === 1 && item.classList.add('--duck-blue')
      index === 2 && item.classList.add('--main-blue')
    })
  }

  const tl = gsap.timeline({
    defaults: { ease: 'power3.out' },
    onComplete: () => {
      titleLines && titleLines.revert()

      gsap.set(content, { clearProps: 'y,opacity' })
      gsap.set(subtitle, { clearProps: 'y,opacity' })
      gsap.set(searchTitle, { clearProps: 'y,opacity' })
      gsap.set(searchBar, { clearProps: 'y,opacity' })
      gsap.set(pageHeader, { clearProps: 'y,rotate,scale,opacity' })
      gsap.set(pageHeaderIconPath, { clearProps: 'drawSVG' })
    }
  })
  .from(pageHeader, { y: 200, rotate: 3, scale: 0.7, opacity: 0, ease: 'expo.inOut', duration: 1.5 }, 0)
  .from(titleLines.lines, { y: 20, opacity: 0, duration: 1, stagger: 0.1 }, 1)
  .from(searchTitle, { y: 20, opacity: 0, duration: 1, stagger: 0.1 }, 1)
  .from(content, { y: 20, opacity: 0, duration: 1 }, 1.25)
  .from(subtitle, { y: 20, opacity: 0, duration: 1 }, 1.35)
  .from(searchBar, { y: -20, opacity: 0, duration: 1 }, 1.35)
  .fromTo(pageHeaderIconPath, { drawSVG: '0%' }, { drawSVG: '100%', ease: 'expo.inOut', duration: 2 }, 0.8)

  return tl
}

export const pageSections = () => {
  const sections = viewStorage.current.querySelectorAll('section')

  if (!sections && !sections.length) return

  const tween = gsap.from(sections, { 
    y: 20, 
    opacity: 0, 
    ease: 'power3.out', 
    duration: 1, 
    onComplete: () => gsap.set(sections, { clearProps: 'y,opacity' }) 
  })

  return tween
}
