import { domStorage } from "../_globals"
import Matter from 'matter-js'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class Footer {
  constructor() {
    
    if (!domStorage.footer) return
    
    // DOM
    this.DOM = {
      el: domStorage.footer,
      footerMatterElementsContainer: domStorage.footer.querySelector('.FooterMatterElements'),
      footerMatterElements: domStorage.footer.querySelectorAll('.FooterMatterElements > div'),
    }

    if (this.DOM.footerMatterElementsContainer) {
      const onLoaded = () => {
        window.removeEventListener('load', onLoaded)
        setTimeout(this.setMatter(), 2500)
      }

      window.addEventListener('load', onLoaded)
    }
  }

  setMatter() {
    const { Engine, Bodies, Body, Common, Composite, Vertices, Svg } = Matter
    
    // Setup
    this.engine = Engine.create({ gravity: { y: 1.2 } })
    this.world = this.engine.world
    
    this.groundBodies = Composite.create()
    this.elementsBodies = Composite.create()

    const scales = []
    
    const createWorld = () => {
      const ground = [
        Bodies.rectangle(
          this.DOM.footerMatterElementsContainer.offsetWidth * 0.5, 
          this.DOM.footerMatterElementsContainer.offsetHeight + 49, 
          this.DOM.footerMatterElementsContainer.offsetWidth, 
          100, 
          { isStatic: true }
        ),
        
        Bodies.rectangle(
          -49, 
          this.DOM.footerMatterElementsContainer.offsetHeight * 0.5, 
          100, 
          this.DOM.footerMatterElementsContainer.offsetHeight, 
          { isStatic: true }
        ),
        
        Bodies.rectangle(
          this.DOM.footerMatterElementsContainer.offsetWidth + 49, 
          this.DOM.footerMatterElementsContainer.offsetHeight * 0.5, 
          100, 
          this.DOM.footerMatterElementsContainer.offsetHeight, 
          { isStatic: true }
        )
      ]
    
      Composite.add(this.groundBodies, ground, true)

      this.DOM.footerMatterElements.forEach((item, index) => {
        // const ratio = Number(item.offsetWidth / item.querySelector('svg').getAttribute('width'))
        // const rect = Vertices.scale(Svg.pathToVertices(item.querySelector('path')), ratio, ratio)
        // const body = Bodies.fromVertices(0, 0, rect, {
        //   restitution: 0.1,
        //   friction: 0.1
        // })

        const { width, height } = item.getBoundingClientRect()

        const body = Bodies.rectangle(0, 0, width, height, {
          render: {
            sprinte: {
              texture: item.querySelector('img').src
            }
          }
        })

        const scale = gsap.utils.random(0.75, 1.25, 0.15)
        scales.push(scale)

        Body.rotate(body, Common.random(-Math.PI / 2, Math.PI / 2))
        Body.scale(body, scale, scale)
        Body.setVelocity(body, { x: 0, y: 50 })
        Composite.add(this.elementsBodies, [ body ], true)
      })
    }

    Composite.add(this.world, this.groundBodies)
    Composite.add(this.world, this.elementsBodies)

    const onRender = (time, deltaTime) => {
      if(this.elementsBodies.bodies) {
        this.elementsBodies.bodies.forEach((body, index) => {
          const { position, angle: angleRadian } = body
          const angle = angleRadian * (180 / Math.PI)

          gsap.set(this.DOM.footerMatterElements[index], { 
            xPercent: -50, 
            yPercent: -50, 
            x:position.x, 
            y: position.y, 
            rotate: angle,
            scale: scales[index],
          })
        })

        Engine.update(this.engine, deltaTime)
      }
    }

    // Create World
    createWorld()

    // ScrollTrigger
    ScrollTrigger.create({
      trigger: this.DOM.el,
      // start: 'center center',
      // markers: true,

      onEnter: () => {
        let height = 0

        this.elementsBodies.bodies.forEach((body, index) => {
          height += body.bounds.max.y - body.bounds.min.y + 10
          Body.setPosition(body, { x : Common.random(0, this.DOM.el.offsetWidth), y : -height })
          Body.setVelocity( body, { x: 0, y : 20 });
        })

        gsap.ticker.add(onRender)
      },
      onLeaveBack: () => {
        gsap.ticker.remove(onRender)
      },
    })

    // Resize
    let resizeTimeout = setTimeout(() => {}, 0)
    let windowWidthResize = window.innerWidth

    const onResize = () => {
      clearTimeout(resizeTimeout)

      resizeTimeout = setTimeout(() => {
        if (windowWidthResize !== window.innerWidth) {
          windowWidthResize = window.innerWidth
          Composite.clear(this.groundBodies)
          Composite.clear(this.elementsBodies)
          createWorld()
        }
      }, 250)
    }

    window.addEventListener('resize', onResize)
  }
}
