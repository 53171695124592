export default class NewsletterHubspot {
  constructor(container) {
    if (!container) return

    this.DOM = { container }
    this.DOM.formContainer = this.DOM.container.querySelector('.modal-form')

    this.formId = this.DOM.formContainer.dataset.hubspotFormId

    if (!this.DOM.formContainer || !this.formId) return

    this.initHubspot()
  }

  initHubspot() {
    if (!window.hbspt) {
      const script = document.createElement('script')
      script.src = '//js-eu1.hsforms.net/forms/embed/v2.js'
      this.DOM.formContainer.appendChild(script)
      script.addEventListener('load', () => this.createHubspotForm())
    } else {
      this.createHubspotForm()
    }
  }

  createHubspotForm() {
    window.hbspt.forms.create({
      portalId: '143406157',
      formId: this.formId,
      'region': 'eu1',
      target: '#modal-newsletter .modal-form',
    })
  }

  destroy() {
    // if (window.hbspt && window.hbspt.forms) window.hbspt.forms.reset()
  }
}
