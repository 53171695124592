import { domStorage, viewStorage } from '../_globals';
import Lenis from '@studio-freight/lenis'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class viewScroll {
  constructor({ wrapper, content } = { wrapper: window, content: document.documentElement }) {
    this.wrapper = wrapper
    this.content = content

    this.scroll = new Lenis({
      wrapper: this.wrapper,
      content: this.content,
      orientation: 'vertical',
      gestureOrientation: 'vertical',
      smoothWheel: true,
      smoothTouch: false,
      wheelMultiplier: 1,
      autoResize: true,
      duration: 0.82,
      easing: (t) => (t === 1 ? 1 : 1 - Math.pow(2, -10 * t)),
    })

    this.scroll.on('scroll', ScrollTrigger.update)
    gsap.ticker.add(time => this.scroll.raf(time * 1000))
    gsap.ticker.lagSmoothing(0)
  }
}
