import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

/**
 * @param {HTMLElement} - HTML Section
 */
export default class FloatingItems {
  constructor(container) {
    if (!container) return

    // DOM
    this.DOM = { container }
    this.DOM.items = this.DOM.container.querySelectorAll('.floating-item')

    if (!this.DOM.items && !this.DOM.items.length) return

    this.setItems()

    // ScrollTrigger (only play the animations when the section is visible)
    this.scrollTrigger = ScrollTrigger.create({
      trigger: this.DOM.container,
      onToggle: (self) => this.DOM.items.forEach(item => self.isActive ? item.tween.resume() : item.tween.pause())
    })

    // Resize
    this.resizeTimeout = setTimeout(() => {}, 0)
    this.onResize = this.onResize.bind(this)
    this.windowWidthResize = window.innerWidth
    window.addEventListener('resize', this.onResize)
  }

  setItems() {
    const { container, items } = this.DOM

    items.forEach(item => {
      this.setRandomItemPosition(item)

      item.tween = gsap.fromTo(item, {
        y: container.offsetHeight + item.offsetWidth,
        rotate: () => gsap.utils.random(-5, 5, 1)
      }, {
        y: () => -(container.offsetHeight + item.offsetWidth),
        rotate: () => gsap.utils.random(-5, 5, 1),
        duration: gsap.utils.random(5, 35, 1),
        ease: 'none',
        overwrite: true,
        repeat: -1,
        onRepeat: () => this.setRandomItemPosition(item)
      })
    })
  }

  setRandomItemPosition(item) { 
    const { container } = this.DOM

    gsap.utils.random([0, 1]) === 0 ? item.classList.add('--o') : item.classList.remove('--o')

    gsap.set(item, { 
      x: () => gsap.utils.random(-item.offsetWidth, container.offsetWidth, 1), 
      scale: () => gsap.utils.random(0.75, 1.2, 0.1)
    })
  }

  onResize() {
    clearTimeout(this.resizeTimeout)

    this.resizeTimeout = setTimeout(() => {
      if (this.windowWidthResize !== window.innerWidth) {
        this.DOM.items.forEach(item => item.tween.kill(true))
        this.setItems()
        this.windowWidthResize = window.innerWidth
      }
    }, 250)
  }

  destroy() {
    if (!this.DOM.items && !this.DOM.items.length) return

    this.DOM.items.forEach(item => item.tween.kill(true))
    window.removeEventListener('resize', this.onResize)
  }
}
