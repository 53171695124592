/*
    Load Plugins / Functions
-------------------------------------------------- */
import { Core } from '@unseenco/taxi'
import { globalStorage } from './_globals'
import { onLeave, onEnter, onEnterCompleted } from './_events'

/* --- Renderers --- */
import Page from './pages/page'
import FrontPage from './pages/front-page'
import PageAbout from './pages/page-about'
import PageWIT from './pages/page-wit'
import PageGutenberg from './pages/page-gutenberg'
import PageTrophy from './pages/page-trophy'
import PageContact from './pages/page-contact'
import PageNewsletter from './pages/page-newsletter'
import SingleActionField from './pages/single-app_actionfield'
import SingleAnnuaire from './pages/single-app_annuaire'
import Archive from './pages/archive'
import ArchiveEvents from './pages/archive-events'
import ArchiveResources from './pages/archive-resources'
import ArchiveAnnuaire from './pages/archive-annuaire'

/* --- Transitions --- */
import PageTransitionDefault from './transitions/page-transition-default'

/* --- Setup Core --- */
globalStorage.taxi = new Core({
  links: 'a:not([target]):not([href^=\\#]):not([data-taxi-ignore]):not(.ab-item)',
  renderers: {
    default: Page,
    frontPage: FrontPage,
    pageAbout: PageAbout,
    pageWit: PageWIT,
    pageContact: PageContact,
    pageNewsletter: PageNewsletter,
    pageGutenberg: PageGutenberg,
    pageTrophy: PageTrophy,
    singleActionField: SingleActionField,
    singleAnnuaire: SingleAnnuaire,
    archive: Archive,
    archiveEvents: ArchiveEvents,
    archiveResources: ArchiveResources,
    archiveAnnuaire: ArchiveAnnuaire
  },
  transitions: {
    default: PageTransitionDefault
  }
})

/* --- Global Events --- */
globalStorage.taxi.on('NAVIGATE_IN', ({ to, trigger }) => onEnter(to, trigger))
globalStorage.taxi.on('NAVIGATE_OUT', ({ from, trigger }) => onLeave(from, trigger))
globalStorage.taxi.on('NAVIGATE_END', ({ to, from, trigger }) => onEnterCompleted(to, from, trigger))
