import gsap from 'gsap'

/**
 * @param {HTMLElement} - DOM section
 * @param {HTMLElement} - DOM items
 * @param {Number} - Limit of the items
 * @param {Number} - Increment number
 */
export default class LoadMore {
  constructor({ container, items, limit, increment }) {
    // container, items, limit, increment
    this.DOM = { container, items }
    this.limit = limit

    if (!this.DOM.container && !this.items && !this.items.length && !this.limit) return

    this.DOM.button = this.DOM.container.querySelector('#LoadMore')

    // Base 
    this.base = 0
    // Add = Number of items generated with events
    this.add = increment

    this.setLoadMore()

    this.DOM.button && this.DOM.button.addEventListener('click', this.loadMore.bind(this))
  }

  setLoadMore() {
    const { items, button } = this.DOM
    const { base, limit } = this

    // The BASE = knowing where we need to slice the new array
    // in the event below ! At first, the base = the limit
    // (Ex: slice(0, 12) where 12 is the limit)
    this.base = limit

    if (items.length <= this.base) return gsap.set(button, { display: 'none' })

    // We hide all the items 
    gsap.set(items, { display: 'none' })

    // We show only the first items with the limit 
    gsap.set([...items].slice(base, limit), { display: 'flex' })
  }

  loadMore(e) {
    e.preventDefault()

    const { items, button } = this.DOM

    // Slicing the items array (for each event)
    gsap.set([...items].slice(this.base, this.base+this.add), { display: 'flex' })

    // Updating the base (for slicing for each event)
    this.base += this.add

    // Removing the "Load more" button
    if (this.base >= items.length) gsap.set(button, { display: 'none' })
  }

  destroy() {
    this.DOM.button && this.DOM.button.removeEventListener('click', this.loadMore.bind(this))
  }
}
