import { isDEVMODE, domStorage, viewStorage } from "../_globals"
import viewScroll from "./view-scroll"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)
import WknSwiper from './wkn-swiper'

export default class TrophyPopups {
  constructor() {
    // DOM
    this.DOM = {
      links: document.querySelectorAll(".Btn.--trophy-popup")
    }

    if (!this.DOM.links && !this.DOM.links.length) return

    // Settings
    this.currentPage = window.location.href

    // Events
    this.createPopup = this.createPopup.bind(this)
    this.close = this.close.bind(this)

    this.DOM.links.forEach(link => link.addEventListener("click", this.createPopup))
  }

  createPopup(e) {
    e.preventDefault()

    const { body } = domStorage
    const { href } = e.currentTarget

    // Stop smooth scroll on the page
    viewStorage.viewScroll.scroll.stop()

    body.classList.add("--loading")
    body.classList.add("--show-trophy-popup")

    fetch(href)
      .then(req => req.text())
      .then(data => {
        body.classList.remove("--loading")

        // Getting the content section
        const parser = new DOMParser()
        const doc = parser.parseFromString(data, "text/html")
        this.DOM.popupPage = doc.querySelector('.PopupPage')
        this.DOM.popup = this.DOM.popupPage.querySelector('.popup')
        this.DOM.close = this.DOM.popupPage.querySelector(".back > .Close")
        this.DOM.awardsSwiper = this.DOM.popupPage.querySelector('.Awards .swiper')

        // Append to the current page
        body.appendChild(this.DOM.popupPage)

        // Custom smooth scroll for the popup
        this.popupScroll = new viewScroll({
          wrapper: this.DOM.popupPage,
          content: this.DOM.popup
        })

        // Update window history with the current href
        window.history.replaceState("", "", href)

        // Init modules
        if (this.DOM.awardsSwiper) this.awardsSwiperModule = new WknSwiper(this.DOM.awardsSwiper, { slidesPerView: 'auto' })

        // Events
        this.DOM.close.addEventListener("click", this.close)

        // Open animation
        this.open()
      })
  }

  destroyPopup() {
    this.popupScroll.scroll.destroy()
    this.DOM.popupPage.remove()

    this.tlIn && this.tlIn.kill()
    this.tlOut && this.tlOut.kill()

    this.DOM.close.removeEventListener("click", this.close)

    viewStorage.viewScroll.scroll.start()

    domStorage.body.classList.remove("--show-trophy-popup")

    this.awardsSwiperModule && this.awardsSwiperModule.destroy()

    window.history.replaceState("", "", this.currentPage)
  }

  open() {
    this.tlIn = gsap.timeline({
      defaults: {},
      onStart: () => {}
    })
    
    this.tlIn.from(this.DOM.popupPage, { 
      opacity: 0, 
      ease: "power3.out", 
      duration: 1 
    }, 0)

    this.tlIn.from(this.DOM.popup, {
      yPercent: 50,
      opacity: 0,
      scale: 0.8,
      ease: "elastic.out(1,0.95)",
      duration: 0.75
    }, 0.85)
  }

  close(e) {
    e && e.preventDefault()

    this.tlOut = gsap.timeline({
      defaults: {},
      onComplete: () => this.destroyPopup()
    })
    
    this.tlOut.to(this.DOM.popup, {
      yPercent: 50,
      opacity: 0,
      scale: 0.8,
      ease: "elastic.in(1,0.95)",
      duration: 0.75
    }, 0)

    this.tlOut.to(this.DOM.popupPage, {
      opacity: 0,
      ease: "power3.out",
      duration: 1
    })
  }

  destroy() {
    this.DOM.links && this.DOM.links.length && this.DOM.links.forEach(link => link.removeEventListener("click", this.createPopup))
  }
}
