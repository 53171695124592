import { viewStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'
gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin)

export default class Contact {
  constructor() {
    if (!viewStorage.current.querySelector('.Contact')) return
    
    // DOM
    this.DOM = { container: viewStorage.current.querySelector('.Contact') }
    this.DOM.path = this.DOM.container.querySelector('.Icon.--france #mPathMask')
    this.DOM.gallerySquares = this.DOM.container.querySelectorAll('.gallery .square-visual')

    // ScrollTrigger France 
    this.tweenFrance = gsap.fromTo(this.DOM.path, {
      drawSVG: '0%'
    }, {
      drawSVG: '100%',
      duration: 2,
      delay: 0.25,
      ease: 'power3.inOut',
      scrollTrigger: {
        trigger: this.DOM.container,
        start: 'top bottom'
      }
    })
  }

  destroy() {
    this.tweenFrance && this.tweenFrance.kill()
  }
}
