import { isDEVMODE, domStorage, viewStorage } from '../_globals'
import viewScroll from './view-scroll'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
import NewsletterHubspot from './newsletter-hubspot'

export default class NewsletterPopups {
  constructor() {
    // DOM
    this.DOM = {
      links: document.querySelectorAll(".Btn.--newsletter-popup")
    }

    if (!this.DOM.links && !this.DOM.links.length) return

    // Settings
    this.currentPage = window.location.href

    this.newsletterHubspotModule = null

    // Events
    this.createPopup = this.createPopup.bind(this)
    this.close = this.close.bind(this)

    this.DOM.links.forEach(link => link.addEventListener("click", this.createPopup))
  }

  createPopup(e) {
    e.preventDefault()

    const { body } = domStorage
    const { href } = e.currentTarget

    // We need to know if the link is from the footer
    // And then get the input value to copy it to the iframe input (hubspot)
    const linkParent = e.currentTarget.parentNode
    this.DOM.footerInput = linkParent.querySelector('input#email')

    // Stop smooth scroll on the page
    viewStorage.viewScroll.scroll.stop()

    body.classList.add("--loading")
    body.classList.add("--show-newsletter-popup")

    fetch(href)
      .then(req => req.text())
      .then(data => {
        body.classList.remove("--loading")

        // Getting the content section
        const parser = new DOMParser()
        const doc = parser.parseFromString(data, "text/html")
        this.DOM.popupPage = doc.querySelector(".PopupPage")
        this.DOM.popup = this.DOM.popupPage.querySelector(".popup")
        this.DOM.close = this.DOM.popupPage.querySelector(".back > .Close")

        // Append to the current page
        body.appendChild(this.DOM.popupPage)

        // Custom smooth scroll for the popup
        this.popupScroll = new viewScroll({
          wrapper: this.DOM.popupPage,
          content: this.DOM.popup
        })
        
        // Update window history with the current href
        window.history.replaceState("", "", href)
        
        // Init Hubspot Module
        this.newsletterHubspotModule = new NewsletterHubspot(this.DOM.popup)

        // Events
        this.DOM.close.addEventListener("click", this.close)

        // Open animation
        this.open()
      })
  }

  destroyPopup() {
    this.popupScroll.scroll.destroy()

    this.DOM.popup.remove()
    this.tlIn && this.tlIn.kill()
    this.tlOut && this.tlOut.kill()
    this.DOM.close.removeEventListener("click", this.close)

    viewStorage.viewScroll.scroll.start()

    domStorage.body.classList.remove("--show-newsletter-popup")

    this.newsletterHubspotModule && this.newsletterHubspotModule.destroy()

    window.history.replaceState("", "", this.currentPage)
  }

  open() {
    this.tlIn = gsap.timeline({
      defaults: {},
      onStart: () => {},
      onComplete: () => {
        // Copy input value to the iframe input (hubspot)
        if (this.DOM.footerInput && this.DOM.footerInput.value) {
              const iframe = this.DOM.popup.querySelector('iframe')
              const inner = iframe.contentWindow.document || iframe.contentDocument
              const hubspotEmail = inner && inner.querySelector('input[name="email"]')

              // Update the value to hubspot form
              if (hubspotEmail) hubspotEmail.value = this.DOM.footerInput.value.toLowerCase()

              // Clean value from Footer input
              this.DOM.footerInput.value = ''
        }
      }
    })
    
    this.tlIn.from(this.DOM.popupPage,{ 
      opacity: 0, 
      ease: "power3.out", 
      duration: 1 
    }, 0)

    this.tlIn.from(this.DOM.popup, {
      yPercent: 50,
      opacity: 0,
      scale: 0.8,
      ease: "elastic.out(1,0.95)",
      duration: 0.75
    }, 0.85)
  }

  close(e) {
    e && e.preventDefault()

    this.tlOut = gsap.timeline({
      defaults: {},
      onComplete: () => this.destroyPopup()
    })
    
    this.tlOut.to(this.DOM.popup, {
      yPercent: 50,
      opacity: 0,
      scale: 0.8,
      ease: "elastic.in(1,0.95)",
      duration: 0.75
    }, 0)
    
    this.tlOut.to(this.DOM.popupPage, {
      opacity: 0,
      ease: "power3.out",
      duration: 1
    })
  }

  destroy() {
    this.DOM.links && this.DOM.links.length && this.DOM.links.forEach(link => link.removeEventListener("click", this.createPopup))
  }
}
