import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import WknSwiper from '../modules/wkn-swiper'
import MobileSwiper from '../modules/mobile-swiper'
import HomeSquaresParallax from '../animations/home-squares-parallax'
import FloatingItems from '../modules/floating-items'

export default class FrontPage extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    // DOM
    this.DOM = {
      pageHeader: viewStorage.current.querySelector('.PageHeader'),
      missionSwiper: viewStorage.current.querySelector('.Mission .swiper'),
      latestNewsSwiper: viewStorage.current.querySelector('.LatestNews .swiper.--mobile'),
      hitDirectory: viewStorage.current.querySelector('.HitDirectory'),
    }

    // Modules
    if (this.DOM.pageHeader) this.pageHeaderModule = new HomeSquaresParallax(this.DOM.pageHeader)
    if (this.DOM.missionSwiper) this.missionSwiperModule = new WknSwiper(this.DOM.missionSwiper, { slidesPerView: 'auto', spaceBetween: 0, centerInsufficientSlides: true })
    if (this.DOM.latestNewsSwiper) this.latestNewsSwiperModule = new MobileSwiper(this.DOM.latestNewsSwiper)
    if (this.DOM.hitDirectory) this.contactFloatingItemsModule = new FloatingItems(this.DOM.hitDirectory)
  }

  onLeaveCompleted() {
    this.pageHeaderModule && this.pageHeaderModule.destroy()
    this.missionSwiperModule && this.missionSwiperModule.destroy()
    this.latestNewsSwiperModule && this.latestNewsSwiperModule.destroy()
    this.contactFloatingItemsModule && this.contactFloatingItemsModule.destroy()
  }
}
