import { Renderer } from '@unseenco/taxi'
import { viewStorage } from '../_globals'

export default class SingleAnnuaire extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter())
    })
  }

  onEnter() {
    // DOM
    this.DOM = {
      button: viewStorage.current.querySelector('.Hubspot .Btn'),
      form: viewStorage.current.querySelector('.Hubspot form')
    }

    this.init()
  }

  init() {
    const { 
      button = null,
      form = null, 
    } = this.DOM

    button.addEventListener('click', () => {
      form.classList.add('--active')
    })
  }

  onLeaveCompleted() {
    this.button && this.button.destroy()
  }
}
