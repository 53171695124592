import { viewStorage, domStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'
gsap.registerPlugin(ScrollTrigger, SplitText, DrawSVGPlugin)

import { 
  header,
  footer,
  basicIntro,
  pageHeaderHome,
  pageHeaderIntro, 
  pageSections 
} from './dom-animations'

export const pageIntro = (delay = 0.5) => {
  const { current } = viewStorage

  const basic_pages = current.classList.contains('Single') || 
                      current.classList.contains('Event') || 
                      current.classList.contains('Resource') || 
                      current.classList.contains('ActionField') ||
                      current.classList.contains('Trophy') ||
                      current.classList.contains('Page') ||
                      current.classList.contains('NotFound')

  const tl = gsap.timeline({
    delay,
    defaults: { ease: 'power3.out', duration: 1 },
    onStart: () => {
      // Refresh ScrollTrigger
      ScrollTrigger.refresh()

      // Stop Scroll
      viewStorage.viewScroll.scroll.stop()

      // Reset scroll with timing for Chrome
      setTimeout(() => window.scrollTo(0, 0), 25)
    },
    onComplete: () => {
      // Refresh ScrollTrigger
      ScrollTrigger.refresh()

      domStorage.body.classList.remove('--animating')
      domStorage.body.classList.remove('--intro')

      // Start Scroll
      viewStorage.viewScroll.scroll.start()

      // Header
      gsap.set(domStorage.header, { clearProps: 'all' })
    },
  })

  // Simple animation intro for basic pages
  if (basic_pages) {
    tl.add(basicIntro(), 0)
    tl.add(header(), 1)
    tl.add(footer(), 1)
  } else {
    tl.add(pageHeaderHome(), 0)
    tl.add(pageHeaderIntro(), 0)
    tl.add(pageSections(), '-=0.85')
    tl.add(header(), 1)
    tl.add(footer(), 1)
  }

  return tl
}
